import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import React, { Suspense, lazy } from 'react';
import {
  AuthProvider, client
} from '@manow/auth'
import './App.css';
import 'antd/dist/antd.css';
import 'react-image-crop/lib/ReactCrop.scss';
import AuthLayout from './views/layout/AuthLayout';

const MainLayout = lazy(() => import('./views/layout/MainLayout'));
const ReadQRCode = lazy(() => import('./pages/readQr'));

const App = () => (
  <AuthProvider client={client}>
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route path={["/signin", "/signup", "/checkverifyemail"]} component={AuthLayout} exact={true}/>
          <Route path="/readqrcode/:eventKey" component={ReadQRCode} exact={true}/>
          <Route path="/readqrcode" component={ReadQRCode} exact={true}/>
          <Route path="/" component={MainLayout} />
        </Switch>
      </Suspense>
    </Router>
  </AuthProvider>
);

export default App;
