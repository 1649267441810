import React from 'react';
import { Card, Layout } from 'antd';
import { Switch, Route } from 'react-router';
import Signin from '../../pages/signin/Signin';
import Signup from '../../pages/signup/Signup';
import CheckVerifyEmail from '../../pages/checkVerifyEmail/CheckVerifyEmail';

const AuthLayout = () => {
  return (
    <Layout>
      <Layout.Content style={{padding: '70px 0px', display: 'flex', alignItems: 'center'}}>
        <Card style={{width: 320, maxWidth: '100%', marginLeft: 'auto', marginRight: 'auto'}}>
          <Switch>
            <Route path="/signin" component={Signin} exact />
            <Route path="/signup" component={Signup} exact />
            <Route path="/checkverifyemail" component={CheckVerifyEmail} exact />
          </Switch>
        </Card>
      </Layout.Content>
      <Layout.Footer style={{ textAlign: 'center' }}>
        Manowpoint ©2020
      </Layout.Footer>
    </Layout>
  )
}

export default AuthLayout
