import React from 'react';
import { Input } from 'antd';
import FormItemWrapper from './FormItemWrapper';

const PasswordInput = ({
  name,
  label,
  placeholder,
  options,
  formItemOptions
}) => {
  return (
    <FormItemWrapper name={name} label={label} options={options} formItemOptions={formItemOptions}>
      <Input.Password placeholder={placeholder} />
    </FormItemWrapper>
  );
};

export default PasswordInput;
