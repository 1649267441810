import React, { useState } from 'react';
import { Icon, Divider, Typography, Button, Checkbox, message } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { useAuth } from '@manow/auth';
import Form from '../../component/Form/Form';
import TextInput from '../../component/Form/TextInput';
import SubmitButton from '../../component/Form/SubmitButtonInput';
import pdf from '../../asset/ข้อตกลงผู้ใช้งานและนโยบายความเป็นส่วนตัว.pdf';

// import { isPossibleNumber } from 'libphonenumber-js'
const validationSchema = yup.object().shape({
  name: yup.string().required(),
  // phone: yup.string().required().test('phone format', 'Invalid phone number', (value) => !value || isPossibleNumber(value)),
  email: yup
    .string()
    .required()
    .email(),
  password: yup
    .string()
    .required()
    .min(6),
  confirmPassword: yup
    .string()
    .required()
    .oneOf([yup.ref('password'), null], 'Confirm password not match')
});

const Signup = () => {
  const { register } = useAuth();
  const history = useHistory();
  const [signedUpEmail, setsignedUpEmail] = useState();
  const [disabled, setDisabled] = useState(true);
  const [checked, setChecked] = useState(false);
  const handleSubmit = async formData => {
    await register({
      ...formData,
      continueURL: global.window.location.origin
    })
      .then(() => setsignedUpEmail(formData.email))
      .catch(error => {
        message.error(error.message, 4);
      });
  };
  const onChangeCheckbox = e => {
    if (e.target.checked) {
      setChecked(e.target.checked);
      setDisabled(false);
    } else {
      setChecked(e.target.checked);
      setDisabled(true);
    }
  };

  if (signedUpEmail) {
    return (
      <React.Fragment>
        <Typography.Title>ขอบคุณสำหรับการลงทะเบียน</Typography.Title>
        <Typography.Paragraph>การลงทะเบียนของคุณยังไม่สมบูรณ์</Typography.Paragraph>
        <Typography.Paragraph>
          กรุณาตรวจสอบอีเมลจาก Manow และคลิกที่ลิงค์ยืนยันอีเมล การลงทะเบียนจึงจะสมบูรณ์
        </Typography.Paragraph>
        <Button block type="primary" onClick={() => history.push('/signin')}>
          Sign in
        </Button>
        <Button block type="link" onClick={() => history.push('/')}>
          Go to home page
        </Button>
      </React.Fragment>
    );
  }
  return (
    <Form onSubmit={handleSubmit} validationSchema={validationSchema}>
      <Typography.Title>Sign up</Typography.Title>
      <Typography.Paragraph>It's free and only take minutes</Typography.Paragraph>
      <TextInput
        label="Email"
        formItemOptions={{ formItemLayout: null }}
        prefix={<Icon type="mail" style={{ fontSize: 13 }} />}
        name="email"
        placeholder="Email"
      />
      <TextInput
        label="Password"
        formItemOptions={{ formItemLayout: null }}
        prefix={<Icon type="lock" style={{ fontSize: 13 }} />}
        name="password"
        placeholder="Password"
        type="password"
      />
      <TextInput
        label="Confirm Password"
        formItemOptions={{ formItemLayout: null }}
        prefix={<Icon type="lock" style={{ fontSize: 13 }} />}
        name="confirmPassword"
        placeholder="Confirm password"
        type="password"
      />
      <Checkbox checked={checked} style={{ marginBottom: '22px' }} onChange={onChangeCheckbox}>
        I agree to the
{' '}
        <a href={pdf} rel="noopener noreferrer" target="_blank">
          {' '}
          terms & conditions
        </a>
      </Checkbox>
      <SubmitButton
        label=""
        formItemOptions={{ formItemLayout: null }}
        block
        type="primary"
        style={{ background: '#54BFC5', color: 'white', width: '100%', textAlign: 'center' }}
        disabled={disabled}
      >
        Create account
      </SubmitButton>
      <Divider>Or</Divider>
      <div style={{ textAlign: 'center' }}>
        Already have an account?
        <Link to="/signin" style={{ color: '#54BFC5' }}>
          {' '}
          Sign in
        </Link>
      </div>
    </Form>
  );
};

export default Signup;
