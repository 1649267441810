import React, { useState } from 'react';
import { Typography, Button } from 'antd';
import { useAuth } from '@manow/auth';
import { useHistory } from 'react-router';

const CheckVerifyEmail = () => {
  const { user, logout, sendVerificationEmail } = useAuth();
  const [verifyEmailSend, setverifyEmailSend] = useState(false);
  const history = useHistory();
  if (!user) {
    history.push('/');
    return null;
  }
  if (user.emailVerified) {
    history.push('/');
    return null;
  }
  const resendEmail = async () => {
    setverifyEmailSend(false);
    await sendVerificationEmail(global.window.location.origin);
    setverifyEmailSend(true);
  };
  return (
    <div>
      <Typography.Title level={2}>กรุณาตรวจสอบอีเมล</Typography.Title>
      <Typography.Text>
        {`
        อีเมล ${user.email} ยังไม่ได้รับการยืนยันทางอีเมล
        กรุณาตรวจสอบอีเมลยืนยันการลงทะเบียน
        และคลิกที่ลิ้งค์ยืนยันการลงทะเบียน
        `}
      </Typography.Text>
      {verifyEmailSend && (
        <div>
          <Typography.Text style={{ color: 'green' }}>
            ระบบได้ส่งอีเมลยืนยันเรียบร้อยแล้ว
          </Typography.Text>
        </div>
      )}
      <div>
        {!verifyEmailSend && (
          <Button block style={{ marginTop: 10 }} onClick={resendEmail}>
            ส่งอีเมลอีกครั้ง
          </Button>
        )}
        {verifyEmailSend && (
          <Button block style={{ marginTop: 10 }} onClick={() => history.push('/')}>
            กลับสู่หน้าแรก
          </Button>
        )}
        <Button block style={{ marginTop: 10 }} type="danger" onClick={logout}>
          ออกจากระบบ
        </Button>
      </div>
    </div>
  );
};

export default CheckVerifyEmail;
