import React from 'react';
import FormItemWrapper from './FormItemWrapper';
import { Button } from 'antd';

const SubmitButtonInput = ({ label = 'บันทึก' , style, formItemOptions, children, disabled }) => {
  return (
    <FormItemWrapper formItemOptions={formItemOptions}>
      <Button htmlType="submit" type="primary" style={style} disabled={disabled}>{children || label}</Button>
    </FormItemWrapper>
  );
};

export default SubmitButtonInput;
