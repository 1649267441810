import React from 'react';
import { useAuth } from '@manow/auth';
import { Icon, Divider, Typography, message, Button } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import * as yup from 'yup';
import Form from '../../component/Form/Form';
import TextInput from '../../component/Form/TextInput';
import PasswordInput from '../../component/Form/PasswordInput';
import SubmitButtonInput from '../../component/Form/SubmitButtonInput';

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .required()
    .email(),
  password: yup
    .string()
    .required()
    .min(6)
});

const Signin = () => {
  const { login, signInWithGoogle, signInWithFacebook, signInWithTwitter } = useAuth();
  const history = useHistory();
  const handleSubmit = async formData => {
    await login(formData).catch(error => {
      message.error(error.message, 4);
    });
    history.push('/');
  };
  const handleLoginGoogle = async () => {
    await signInWithGoogle().catch(error => {
      message.error(error.message, 4);
    });
    history.push('/');
  };
  const handleLoginFacebook = async () => {
    await signInWithFacebook().catch(error => {
      message.error(error.message, 4);
    });
    history.push('/');
  };
  const handleLoginTwitter = async () => {
    await signInWithTwitter().catch(error => {
      message.error(error.message, 4);
    });
    history.push('/');
  };
  return (
    <Form onSubmit={handleSubmit} validationSchema={validationSchema}>
      <Typography.Title>Sign in</Typography.Title>
      <Typography.Paragraph>Login using your email and password</Typography.Paragraph>
      <TextInput
        label=""
        formItemOptions={{
          wrapperCol: {
            xs: { span: 24 },
            sm: { span: 24 }
          }
        }}
        prefix={<Icon type="mail" style={{ fontSize: 13 }} />}
        name="email"
        placeholder="Email"
      />
      <PasswordInput
        label=""
        formItemOptions={{
          wrapperCol: {
            xs: { span: 24 },
            sm: { span: 24 }
          }
        }}
        prefix={<Icon type="lock" style={{ fontSize: 13 }} />}
        name="password"
        placeholder="Password"
        type="password"
      />
      <SubmitButtonInput
        label="Sign in"
        block
        formItemOptions={{
          wrapperCol: {
            xs: { span: 24 },
            sm: { span: 24 }
          }
        }}
        style={{ background: '#54BFC5', color: 'white', width: '100%', textAlign: 'center' }}
      >
        Login
      </SubmitButtonInput>
      <div style={{ textAlign: 'right' }}>
        <Link to="/forgotPassword">Forgot password</Link>
      </div>
      <Divider>Or</Divider>
      <Button
        block
        size="large"
        style={{
          background: '#4469B0',
          color: 'white',
          width: '100%',
          textAlign: 'center',
          marginBottom: '19px',
          fontSize: '14px',
          fontWeight: 'bold',
          height: '57px'
        }}
        onClick={() => handleLoginFacebook()}
      >
        <div
          style={{
            display: 'inline-flex',
            width: '100%',
            height: '28px',
            margin: '5px 0 0 36px'
          }}
        >
          <Icon type="facebook" theme="filled" style={{ fontSize: '28px' }} />
          <div style={{ padding: '5px 0 0 10px' }}>Continue with Facebook</div>
        </div>
      </Button>
      <Button
        block
        size="large"
        style={{
          background: '#D94A42',
          color: 'white',
          width: '100%',
          textAlign: 'center',
          marginBottom: '19px',
          fontSize: '14px',
          fontWeight: 'bold',
          height: '57px'
        }}
        onClick={() => handleLoginGoogle()}
      >
        <div
          style={{
            display: 'inline-flex',
            width: '100%',
            height: '28px',
            margin: '5px 0 0 36px'
          }}
        >
          <Icon type="google" style={{ fontSize: '28px' }} />
          <div style={{ padding: '5px 0 0 10px' }}>Continue with Google</div>
        </div>
      </Button>
      <Button
        block
        size="large"
        style={{
          background: '#00ACEE',
          color: 'white',
          width: '100%',
          textAlign: 'center',
          marginBottom: '31px',
          fontSize: '14px',
          fontWeight: 'bold',
          height: '57px'
        }}
        onClick={() => handleLoginTwitter()}
      >
        <div
          style={{
            display: 'inline-flex',
            width: '100%',
            height: '28px',
            margin: '5px 0 0 36px'
          }}
        >
          <Icon type="twitter" style={{ fontSize: '28px' }} />
          <div style={{ padding: '5px 0 0 10px' }}>Continue with Twitter</div>
        </div>
      </Button>
      <div style={{ textAlign: 'center' }}>
        Don't have an account? 
{' '}
<Link to="/signup">Create account</Link>
      </div>
    </Form>
  );
};

export default Signin;
