import React from 'react';
import { Form } from 'antd';
import _get from 'lodash/get';
import { useFormContext } from './Form';

const FormItemWrapper = ({
  name,
  label,
  children,
  options,
  parse = v => v,
  defaultValue = '',
  formItemOptions,
  formStyle = '',
  formRules = []
}) => {
  const { getFieldDecorator, initialValues } = useFormContext();

  if (!name) {
    return <Form.Item label="">{children}</Form.Item>;
  }
  return (
    <Form.Item label={label} labelAlign="left" {...formItemOptions} style={formStyle}>
      {getFieldDecorator(name, {
        ...options,
        initialValue: parse(_get(initialValues, name, defaultValue)),
        rules: formRules
      })(children)}
    </Form.Item>
  );
};

export default FormItemWrapper;
